"use client";

import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  Button,
  NavbarMenuItem,
  NavbarMenuToggle,
  Switch,
} from "@nextui-org/react";
import { AcmeLogo } from "@/assets/logos/AcmeLogo";
import { COMPANY_NAME, NAV_ITEMS } from "@/utils/constant";
import { MoonIcon } from "@/assets/icons/MoonIcon";
import { SunIcon } from "@/assets/icons/SunIcon";
import { useTheme } from "next-themes";
import { usePathname } from "next/navigation";
import { CONTACT_PATH } from "@/utils/url";
import Link from "next/link";

const Nav = () => {
  const pathname = usePathname();
  const [isSelected, setIsSelected] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { theme, setTheme } = useTheme();
  return (
    <>
      <Navbar
        // isBordered
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
      >
        <NavbarContent className="sm:hidden" justify="start">
          <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          />
        </NavbarContent>

        <NavbarContent className="sm:hidden pr-3" justify="center">
          <NavbarBrand>
            <AcmeLogo />
            <p className="font-bold text-inherit uppercase">{COMPANY_NAME}</p>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-4">
          <NavbarBrand>
            <AcmeLogo />
            <p className="font-bold text-inherit uppercase">{COMPANY_NAME}</p>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          {NAV_ITEMS.map((item) => (
            <NavbarItem isActive={pathname === item.path} key={`${item.title}`}>
              <Link
                className={pathname === item.path ? "link text-secondary active": "link"}
                color={pathname === item.path ? "secondary" : "foreground"}
                href={item.path}
              >
                {item.title}
              </Link>
            </NavbarItem>
          ))}
        </NavbarContent>

        <NavbarContent justify="end">
          <NavbarItem className="hidden lg:flex">
            <Switch
              isSelected={isSelected}
              color="secondary"
              onValueChange={setIsSelected}
              onClick={() => {
                if (isSelected) setTheme("dark");
                else setTheme("light");
              }}
              thumbIcon={({ isSelected, className }) =>
                isSelected ? (
                  <MoonIcon className={className} />
                ) : (
                  <SunIcon className={className} />
                )
              }
            ></Switch>
          </NavbarItem>
          <NavbarItem>
            <Button as={Link} color="secondary" href={CONTACT_PATH} variant="bordered">
              Contact Us
            </Button>
          </NavbarItem>
        </NavbarContent>

        <NavbarMenu>
          <NavbarMenuItem>
            <Link color="foreground" href="/home">
              Home
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem isActive>
            <Link href="#about" color="secondary">
              About
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link color="foreground" href="#testimonials">
              Integrations
            </Link>
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>
      {/* <Navbar>
        <NavbarBrand>
          <AcmeLogo />
          <p className="font-bold text-inherit uppercase">{COMPANY_NAME}</p>
        </NavbarBrand>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          <NavbarItem>
            <Link color="foreground" href="/home">
              Home
            </Link>
          </NavbarItem>
          <NavbarItem isActive>
            <Link href="#about" color="secondary">
              About
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link color="foreground" href="#testimonials">
              Integrations
            </Link>
          </NavbarItem>
        </NavbarContent>

        <NavbarContent as="div" justify="end">
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Avatar
                isBordered
                as="button"
                className="transition-transform"
                color="secondary"
                name="Jason Hughes"
                size="sm"
                src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
              />
            </DropdownTrigger>
            <DropdownMenu aria-label="Profile Actions" variant="flat">
              <DropdownItem key="profile" className="h-14 gap-2">
                <p className="font-semibold">Signed in as</p>
                <p className="font-semibold">zoey@example.com</p>
              </DropdownItem>
              <DropdownItem key="settings">My Settings</DropdownItem>
              <DropdownItem key="team_settings">Team Settings</DropdownItem>
              <DropdownItem key="analytics">Analytics</DropdownItem>
              <DropdownItem key="system">System</DropdownItem>
              <DropdownItem key="configurations">Configurations</DropdownItem>
              <DropdownItem key="help_and_feedback">
                Help & Feedback
              </DropdownItem>
              <DropdownItem key="logout" color="danger">
                Log Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarContent>
      </Navbar> */}
    </>
  );
};

export default Nav;
